<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-toolbar-title class="headline font-weight-semibold">
        User Management
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-card flat :loading="loading" class="mt-4">
      <v-simple-table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Organisation</th>
            <th>Date Added</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="admin in adminUsers" :key="admin.id">
            <td>{{ admin.name }}</td>
            <td>{{ admin.email }}</td>
            <td>{{ admin.role }}</td>
            <td>
              <div v-for="org in admin.organisations" :key="org.id">
                {{ org.name }}
              </div>
            </td>
            <td>{{ admin.addedDate }}</td>
            <td>
              <confirm-dialog
                title="Confirm Delete"
                body="Are you sure you want to delete this account ?"
                confirm-text="Yes, Delete it"
                width="400"
                @confirmed="deleteAdmin(admin)"
              >
                <template v-slot="{ on }">
                  <v-btn v-on="on" icon color="grey lighten-1">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </confirm-dialog>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <admin-form @saved="adminSaved" />
  </section>
</template>

<script>
import AdminForm from './AdminForm.vue'
import Admin from '@/models/Admin'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { findIndex } from 'lodash'

export default {
  components: {
    AdminForm,
    ConfirmDialog,
  },

  data() {
    return {
      adminUsers: [],
      loading: false,
    }
  },

  methods: {
    async fetchAdminUsers() {
      this.loading = true
      const res = await Admin.get()
      this.adminUsers = res.data.map((admin) => new Admin(admin))
      this.loading = false
    },
    adminSaved() {
      this.fetchAdminUsers()
    },
    deleteAdmin(admin) {
      admin.delete()
      const i = findIndex(this.adminUsers, { id: admin.id })
      if (i > -1) {
        this.adminUsers.splice(i, 1)
      }
    },
  },

  created() {
    this.fetchAdminUsers()
  },
}
</script>

<style></style>
