<template>
  <v-dialog v-model="dialog" v-bind="$attrs" :width="width">
    <template v-slot:activator="{ on }">
      <slot :on="on">
        <v-btn color="error" dark v-on="on"> Button </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="subtitle-1 mt-4">
        {{ body }}
        <slot name="body"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-4">
        <v-btn :loading="loading" depressed @click="cancel">
          {{ cancelText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="loading" depressed @click="confirm">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirm',
    },
    body: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    width: {
      type: [String, Number],
      default: 300,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    cancel() {
      this.dialog = false
      this.$emit('cancelled')
    },

    confirm() {
      this.dialog = false
      this.$emit('confirmed')
    },
  },
}
</script>

<style></style>
