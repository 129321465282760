<template>
  <v-dialog max-width="600" v-model="dialog" eager persistent>
    <v-card>
      <v-toolbar>
        <v-toolbar-title
          class="d-flex w-full justify-space-between align-center"
        >
          <div>Add New Admin</div>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-8">
        <v-alert
          border="top"
          color="green"
          outlined
          dark
          icon="mdi-check"
          v-if="admin"
        >
          New admin user added successfully. Use the login credentials below.
          <div class="mt-2">
            <strong>Email: {{ admin.email }}</strong>
          </div>
          <div>
            <strong>Password: {{ admin.raw_password }}</strong>
          </div>
        </v-alert>

        <v-form @submit.prevent="save">
          <label class="text-field-label">Name</label>
          <v-text-field
            class="mt-2"
            flat
            solo
            v-model="form.name"
            :error-messages="form.$getError('name')"
          />

          <label class="text-field-label">Email</label>
          <v-text-field
            class="mt-2"
            flat
            solo
            v-model="form.email"
            :error-messages="form.$getError('email')"
          />

          <label class="text-field-label">Role</label>
          <v-select
            class="mt-2"
            flat
            solo
            :items="roles"
            v-model="form.role"
            :error-messages="form.$getError('role')"
          />
          <template v-if="form.role == 'org_manager'">
            <label class="text-field-label">Organisation</label>
            <v-autocomplete
              class="mt-2"
              flat
              solo
              :items="organisations"
              item-value="id"
              item-text="name"
              v-model="form.organisation"
              :error-messages="form.$getError('organisation')"
            />
          </template>
          <div class="d-flex mt-6">
            <v-btn
              depressed
              type="button"
              color="grey lighten-3"
              large
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn type="submit" color="primary" large> Submit </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        id="add-admin-button"
        elevation="16"
        v-bind="attrs"
        v-on="on"
        rounded
        large
        dark
      >
        <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
        Add New Admin
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import Organisation from '@/models/Organisation'
import Form from '@/utils/form'
import Admin from '@/models/Admin'

export default {
  data() {
    return {
      dialog: false,
      organisations: [],
      roles: [
        { value: 'super_admin', text: 'Super Admin' },
        { value: 'org_manager', text: 'Organisation Manager' }
      ],
      form: new Form({
        name: '',
        email: '',
        role: '',
        organisation: null
      }),
      admin: null
    }
  },

  methods: {
    async fetchOrganisations() {
      this.organisations = await Organisation.$get()
    },
    async save() {
      try {
        this.admin = null
        const user = new Admin(this.form.$data())
        const res = await user.save()
        this.admin = new Admin(res.data)
        this.resetForm()
        this.$emit('saved', this.admin)
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }
      }
    },
    resetForm() {
      this.form = new Form({
        name: '',
        email: '',
        role: '',
        organisation: null
      })
    }
  },

  mounted() {
    this.fetchOrganisations()
  },

  watch: {
    dialog() {
      this.admin = null
      this.resetForm()
    }
  }
}
</script>

<style>
#add-admin-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
}
</style>
